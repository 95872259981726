/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { createRoot } from "react-dom/client";
import "@ht/components/dist/style.css";
import "@ht/shared/fonts/fonts.css";
import "@ht/shared/fonts/font-awesome.css";
import "@ht/shared/fonts/ionicons.css";
import "@ht/shared/fonts/flag-icon.min.css";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "./assets/css/tailwind.css";
import App from "./App";

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<App />);
